import React, { useEffect, useState } from "react";
import SelectOption from "../../assets/JsonFiles/SelectOption.json";
import axios from "axios";
import Logo from "../../assets/images/ventureof.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    company_website: "",
    company_sector: "",
    job_title: "",
    company_size: "",
    business_email: "",
    phone_number: "",
    country: "",
    key_objective: "",
    message: "",
  });

  const [formField, setFormField] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    company_website: "",
    company_sector: "",
    job_title: "",
    company_size: "",
    business_email: "",
    phone_number: "",
    country: "",
    key_objective: "",
    message: "",
  });
  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    company_website: "",
    company_sector: "",
    job_title: "",
    company_size: "",
    business_email: "",
    phone_number: "",
    country: "",
    key_objective: "",
    message: "",
  });

 

  const fieldNames = {
    first_name: "First Name",
    last_name: "Last Name",
    company_name: "Company Name",
    company_website: "Company Website",
    company_sector: "Company Sector",
    job_title: "Job Title",
    company_size: "Company Size",
    business_email: "Business Email",
    phone_number: "Phone Number",
    country: "Country",
    key_objective: "Key Objective",
    message: "Message",
  };

  // const [sucessMsg, setSucessMessage] = useState({})
  // const [isSucess, setIsSucess] = useState(false)

  // useEffect(() => {
  //   if (isSucess) {
  //     toast.error(sucessMsg, {
  //       position: "top-right",
  //       autoClose: 8000, // 8 seconds
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // }, [isSucess]);

  const formInputHandel = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const formSubmit = async () => {
    const formErrors = {};
    Object.keys(error).forEach((key) => {
      if (!formData[key]) {
        const fieldName = fieldNames[key] || key;
        formErrors[key] = `${fieldName} is required`;
      }
    });
    setError(formErrors);

    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await axios.post(
          "https://liveuat.com:5437/api/enquiry",
          formData
        );
        console.log("response", response);
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000, // 3 seconds duration
          });
          setFormData(formField)
          setError(formField)
        }
      } catch (error) {
        console.log(error);
        toast.error("Failed to submit the form. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex justify-center items-center">
        <div className="container flex justify-center flex-col items-center">
          <img src={Logo} className="w-96 mt-5" />
          <div className="text-center w-[550px] mb-28">
            {/* <p className="text-[36px] text-[#111111] font-medium mb-[30px]">
              Apply to Exhibit or Sponsor at FinTech Week 2024
            </p> */}

            {/* <p className="text-base text-[#222222] ">
              Please complete the form below for further information on
              exhibition and sponsorship opportunities at FinTech Week
              2024.
            </p> */}
          </div>

          <div className="grid grid-cols-2 gap-10 w-full p-10">
            <div className="w-full">
              <label className="block text-base text-[#222222] mb-4">
                First Name
              </label>
              <input
                type="text"
                onChange={(e) => formInputHandel(e)}
                value={formData.first_name}
                placeholder="First Name"
                name="first_name"
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
              />
              {!formData.first_name && (
                <p className="text-xs text-red-500">{error.first_name}</p>
              )}
            </div>

            <div className="w-full">
              <label className="block text-base text-[#222222] mb-4">
                Last Name
              </label>
              <input
                type="text"
                onChange={(e) => formInputHandel(e)}
                value={formData.last_name}
                placeholder="Last Name"
                name="last_name"
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
              />
              {!formData.last_name && (
                <p className="text-xs text-red-500">{error.last_name}</p>
              )}
            </div>

            <div className="w-full">
              <label className="block text-base text-[#222222] mb-4">
                Company Name
              </label>
              <input
                type="text"
                onChange={(e) => formInputHandel(e)}
                placeholder="Company Name"
                value={formData.company_name}
                name="company_name"
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
              />
              {!formData.company_name && (
                <p className="text-xs text-red-500">{error.company_name}</p>
              )}
            </div>

            <div className="w-full">
              <label className="block text-base text-[#222222] mb-4">
                Company Website
              </label>
              <input
                type="text"
                onChange={(e) => formInputHandel(e)}
                placeholder="Company Website"
                value={formData.company_website}
                name="company_website"
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
              />
              {!formData.company_website && (
                <p className="text-xs text-red-500">{error.company_website}</p>
              )}
            </div>

            <div className="w-full">
              <label className="block text-base text-[#222222] mb-4">
                Company Sector
              </label>
              <select
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
                name="company_sector"
                onChange={(e) => formInputHandel(e)}
                value={formData.company_sector}
              >
                <option value="">Select one</option>
                {SelectOption.Sector.map((sector, index) => (
                  <option key={index} value={sector}>
                    {sector}
                  </option>
                ))}
              </select>
              {!formData.company_sector && (
                <p className="text-xs text-red-500">{error.company_sector}</p>
              )}
            </div>

            <div className="w-full">
              <label className="block text-base text-[#222222] mb-4">
                Job Title
              </label>
              <select
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
                name="job_title"
                onChange={(e) => formInputHandel(e)}
                value={formData.job_title}
              >
                <option value="">Select one</option>
                {SelectOption.Job.map((job, index) => (
                  <option key={index} value={job}>
                    {job}
                  </option>
                ))}
              </select>
              {!formData.job_title && (
                <p className="text-xs text-red-500">{error.job_title}</p>
              )}
            </div>

            <div className="w-full">
              <label className="block text-base text-[#222222] mb-4">
                Company size
              </label>
              <input
                type="text"
                placeholder="Company Size"
                value={formData.company_size}
                onChange={(e) => formInputHandel(e)}
                name="company_size"
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
              />
              {!formData.company_size && (
                <p className="text-xs text-red-500">{error.company_size}</p>
              )}
            </div>

            <div className="w-full">
              <label className="block text-base text-[#222222] mb-4">
                Business Email
              </label>
              <input
                type="text"
                placeholder="Business Email"
                value={formData.business_email}
                onChange={(e) => formInputHandel(e)}
                name="business_email"
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
              />
              {!formData.business_email && (
                <p className="text-xs text-red-500">{error.business_email}</p>
              )}
            </div>

            <div className="w-full">
              <label className="block text-base text-[#222222] mb-4">
                Phone
              </label>

              <input
                type="text"
                placeholder="Phone"
                value={formData.phone_number}
                onChange={(e) => formInputHandel(e)}
                name="phone_number"
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
              />
              {!formData.phone_number && (
                <p className="text-xs text-red-500">{error.phone_number}</p>
              )}
            </div>

            <div className="w-full">
              <label className="block text-base text-[#222222] mb-4">
                Country / Region
              </label>
              <select
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
                name="country"
                onChange={(e) => formInputHandel(e)}
                value={formData.country}
              >
                <option value="">Select one</option>
                {SelectOption.Country.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              {!formData.country && (
                <p className="text-xs text-red-500">{error.country}</p>
              )}
            </div>

            <div className="w-full col-span-2">
              <label className="block text-base text-[#222222] mb-4">
                What is your key objective for partnership with us
              </label>
              <select
                className="border-[1px] border-black py-2 px-3 h-[57px] w-full"
                name="key_objective"
                onChange={(e) => formInputHandel(e)}
                value={formData.key_objective}
              >
                <option value="">Select one</option>
                {SelectOption.Objective.map((objective, index) => (
                  <option key={index} value={objective}>
                    {objective}
                  </option>
                ))}
              </select>
              {!formData.key_objective && (
                <p className="text-xs text-red-500">{error.key_objective}</p>
              )}
            </div>

            <div className="w-full col-span-2">
              <label className="block text-base text-[#222222] mb-4">
                Message
              </label>
              <textarea
                name="message"
                rows="5"
                value={formData.message}
                onChange={(e) => formInputHandel(e)}
                className="w-full p-3 border-[1px] border-black "
                placeholder="Enter your comments here..."
              ></textarea>
              {!formData.message && (
                <p className="text-xs text-red-500">{error.message}</p>
              )}
            </div>
          </div>

          <button
            type="button"
            onClick={() => formSubmit()}
            className="rounded-lg bg-[#0391CF] p-2 px-3 text-white"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default Contact;
